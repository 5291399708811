<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">년월</div>
            <ul class="content">
              <li class="item date">
                <input-date
                  :format="DATE_FORMAT_YYYY_MM"
                  :notClear="true"
                  depth="Year"
                  v-model="searchOptions.yearMonth"
                  type="lookup-condition"
                  @change="onYearMonthChange"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="onSearchButtonClick"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <ul class="lookup-button">
          <li>
            <erp-button
              button-div="SAVE"
              :is-icon-custom="true"
              @click.native="onDataInterfaceButtonClicked"
            >
              데이터 연계
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <div
          :class="[
            'article-left',
            !isIncheonAirportDataInterfaceVisible && 'active',
          ]"
        >
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section
            :class="[
              'article-section',
              'section-01',
              'accordion',
              isIncheonAirportDataInterfaceVisible ? 'left' : 'right',
            ]"
          >
            <div class="section-header">
              <div class="header-left">
                <div
                  class="header-title"
                  @click="isIncheonAirportDataInterfaceVisible = !isIncheonAirportDataInterfaceVisible"
                >
                  인천공항 월별 마감 목록
                </div>
                <div class="header-caption">[{{ incheonAirportMonthlyClosingListCount }}건]</div>
              </div>
            </div>
            <div class="section-body">
              <ejs-grid-wrapper
                ref="incheonAirportMonthlyClosingGrid"
                v-bind="incheonAirportMonthlyClosingGridProps"
                :dataSource="incheonAirportMonthlyClosingList"
                :isSelectedRowRetain="false"
                :isAutoSelectRow="false"
                :isAutoSelectCell="false"
                @headerCellInfo="onIncheonAirportMonthlyClosingGridHeaderCellInfo"
                @queryCellInfo="onIncheonAirportMonthlyClosingGridQueryCellInfo"
                @actionComplete="onIncheonAirportMonthlyClosingGridActionComplete"
                @recordClick="onIncheonAirportMonthlyClosingGridRecordClick"
              />
            </div>
          </section>
        </div>
        <div
          :class="[
            'article-right',
            isIncheonAirportDataInterfaceVisible && 'active',
          ]"
        >
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section
            :class="[
              'article-section',
              'section-02',
              'accordion',
              isIncheonAirportDataInterfaceVisible ? 'right' : 'left',
            ]"
          >
            <div class="section-header">
              <div class="header-left">
                <div
                  class="header-title"
                  @click="isIncheonAirportDataInterfaceVisible = !isIncheonAirportDataInterfaceVisible"
                >
                  인천공항 데이터 연계 목록
                </div>
                <div class="header-caption">[{{ incheonAirportDataInterfaceListCount }}건]</div>
              </div>
            </div>
            <div class="section-body">
              <ejs-grid-wrapper
                ref="incheonAirportDataInterfaceGrid"
                v-bind="incheonAirportDataInterfaceGridProps"
                :dataSource="incheonAirportDataInterfaceList"
                @headerCellInfo="onIncheonAirportDataInterfaceGridHeaderCellInfo"
                @queryCellInfo="onIncheonAirportDataInterfaceGridQueryCellInfo"
                @actionComplete="onIncheonAirportDataInterfaceGridActionComplete"
              />
            </div>
          </section>
        </div>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .content-body .article-section .section-body {overflow: hidden; border: none}
body .content-body .article-section.accordion.left .header-title {background-image: url(../../assets/images/common/accordion-left.png)}
body .content-body .article-section.accordion.right .header-title {background-image: url(../../assets/images/common/accordion-right.png)}
body .content-body .article-left.active {width: 507px}
body .content-body .article-left:not(.active) {width: calc(100% - 337px)}
body .content-body .article-right.active {width: 337px}
body .content-body .article-right:not(.active) {width: calc(100% - 507px)}

body .lookup-condition .item.date {width: 100px;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDate from "@/components/common/datetime/InputDate";

import {Aggregate, Edit, ExcelExport, Filter, ForeignKey, Group, Page, Resize, DetailRow} from "@syncfusion/ej2-vue-grids";

import {
  DATE_FORMAT_YYYY_MM,
  getFormattedDate,
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
  getWeekdayWeekendCaption,
  getWeekdayWeekendCaptionColor,
  getDateFromServer,
  getAge, DATE_FORMAT_YYYY_MM_DD,
} from "@/utils/date";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {getStoreBsnMstInfo} from "@/api/bizCodeManagement";
import moment from "moment";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "IncheonAirportDataInterface",
  components: {
    EjsGridWrapper,
    InputDate,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      DATE_FORMAT_YYYY_MM,
      searchOptions: {
        yearMonth: null,
      },
      incheonAirportMonthlyClosingList: [],
      incheonAirportDataInterfaceList: [],
      incheonAirportMonthlyClosingListCount: 0,
      incheonAirportDataInterfaceListCount: 0,
      isIncheonAirportDataInterfaceVisible: false,
      settlement: null,
    };
  },
  created() {
    this.searchOptions.yearMonth = moment().format(DATE_FORMAT_YYYY_MM);
  },
  computed: {
    isPopupOpened() {
      return false;
    },
    incheonAirportDataInterfaceChildGridProps() {
      return {
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          type: "Single", mode: "Both", enableToggle: false
        },
        allowFiltering: false,
        allowGrouping: false,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        provides: [
          Aggregate,
          Edit,
          ForeignKey,
          Group,
          Resize,
          Filter,
          Page,
          ExcelExport,
          DetailRow,
        ],
        columns: [
          {
            field: "salesDate",
            headerText: "매출일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "salesTime",
            headerText: "매출시간",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "storeCode",
            headerText: "매장코드",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            groupCode: "STORE_CODE",
            isCommonCodeField: true,
          },
          {
            field: "posNo",
            headerText: "POS번호",
            type: "string",
            minWidth: 16,
            width: 90,
          },
          {
            field: "slipNo",
            headerText: "전표번호",
            type: "string",
            minWidth: 16,
            width: 90,
          },
          {
            field: "totDcAmt",
            headerText: "할인금액",
            type: "number",
            minWidth: 16,
            width: 90,
            textAlign: "right",
            format: "N",
          },
          {
            field: "sexCode",
            headerText: "성별",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            groupCode: "SEX_CODE",
            isCommonCodeField: true,
          },
          {
            field: "nation",
            headerText: "국적",
            type: "string",
            minWidth: 16,
            width: 90,
            groupCode: "NATION_CODE",
            isCommonCodeField: true,
          },
          {
            field: "age",
            headerText: "연령",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
        ]
      };
    },
    incheonAirportMonthlyClosingGridProps() {
      return {
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          type: "Single", mode: "Both", enableToggle: false
        },
        allowFiltering: true,
        allowGrouping: false,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        allowPaging: false,
        provides: [
          Aggregate,
          Edit,
          ForeignKey,
          Group,
          Resize,
          Filter,
          Page,
          ExcelExport,
        ],
        columns: [
          {
            field: "bsnDate",
            headerText: "영업일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "dwCode",
            headerText: "요일",
            type: "string",
            minWidth: 16,
            width: 50,
            textAlign: "center",
            groupCode: "DW_CODE",
            isCommonCodeField: true,
          },
          {
            field: "bsnCode",
            headerText: "구분",
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
            groupCode: "BSN_CODE",
            isCommonCodeField: true,
          },
          {
            field: "isClose",
            headerText: "일마감",
            type: "boolean",
            minWidth: 16,
            width: 70,
            textAlign: "center",
            editType: "booleanedit",
            displayAsCheckBox: true,
          },
          {
            headerText: "데이터 연계 여부",
            columns: [
              {
                field: "isIfSales",
                headerText: "매출",
                type: "boolean",
                minWidth: 16,
                width: 70,
                textAlign: "center",
                editType: "booleanedit",
                displayAsCheckBox: true,
              },
              {
                field: "isIfPayment",
                headerText: "정산",
                type: "boolean",
                minWidth: 16,
                width: 70,
                textAlign: "center",
                editType: "booleanedit",
                displayAsCheckBox: true,
              },
            ],
          },
          {
            headerText: "데이터 연계 내역",
            columns: [
              {
                field: "totAmt",
                headerText: "총매출",
                type: "number",
                minWidth: 16,
                width: 90,
                textAlign: "right",
                format: "N",
              },
              {
                field: "cleanAmt",
                headerText: "순매출",
                type: "number",
                minWidth: 16,
                width: 90,
                textAlign: "right",
                format: "N",
              },
              {
                field: "dcAmt",
                headerText: "할인금액",
                type: "number",
                minWidth: 16,
                width: 90,
                textAlign: "right",
                format: "N",
              },
              {
                field: "cardAmt",
                headerText: "카드",
                type: "number",
                minWidth: 16,
                width: 90,
                textAlign: "right",
                format: "N",
              },
              {
                field: "cashAmt",
                headerText: "현금",
                type: "number",
                minWidth: 16,
                width: 90,
                textAlign: "right",
                format: "N",
              },
              {
                field: "etcAmt",
                headerText: "기타",
                type: "number",
                minWidth: 16,
                width: 90,
                textAlign: "right",
                format: "N",
              },
              {
                field: "payTotAmt",
                headerText: "정산합계",
                type: "number",
                minWidth: 16,
                width: 90,
                textAlign: "right",
                format: "N",
              },
            ]
          },
        ]
      };
    },
    incheonAirportDataInterfaceGridProps() {
      return {
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          type: "Single", mode: "Both", enableToggle: false
        },
        allowFiltering: true,
        allowGrouping: true,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        allowPaging: true,
        groupSettings: {
          columns: ["groupSalesDate"],
          showDropArea: false,
        },
        aggregates: [
          {
            columns: [
              {
                field: "slipNo",
                aggregationType: "TotalCaption",
                customAggregate: "합계",
              },
              {
                field: "salesLength",
                aggregationType: "TotalSum",
              },
              {
                field: "totAmt",
                aggregationType: "TotalSum",
              },
              // {
              //   field: "cleanAmt",
              //   aggregationType: "TotalSum",
              // },
              // {
              //   field: "totAmtByNotVat",
              //   aggregationType: "TotalSum",
              // },
              {
                field: "cleanAmtByNotVat",
                aggregationType: "TotalSum",
              },
              {
                field: "discountsLength",
                aggregationType: "TotalSum",
              },
              {
                field: "totDcAmt",
                aggregationType: "TotalSum",
              },
              {
                field: "paymentsLength",
                aggregationType: "TotalSum",
              },
              {
                field: "totCardPayAmt",
                aggregationType: "TotalSum",
              },
              {
                field: "totCashPayAmt",
                aggregationType: "TotalSum",
              },
              {
                field: "totEtcPayAmt",
                aggregationType: "TotalSum",
              },
            ]
          }
        ],
        pageSettings: { pageSize: 50 },
        provides: [
          Aggregate,
          Edit,
          ForeignKey,
          Group,
          Resize,
          Filter,
          Page,
          ExcelExport,
          // DetailRow,
        ],
        // childGrid: this.incheonAirportDataInterfaceChildGridProps,
        columns: [
          {
            field: "groupSalesDate",
            headerText: "그룹매출일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "salesDate",
            headerText: "매출일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "salesTime",
            headerText: "매출시간",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "course",
            headerText: "코스",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            groupCode: "COURSE_CODE",
            isCommonCodeField: true,
          },
          {
            field: "time",
            headerText: "시간",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
          },
          {
            field: "groupName",
            headerText: "단체명",
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "left",
          },
          {
            field: "slipNo",
            headerText: "전표번호",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "salesLength",
            headerText: "매출건수",
            type: "number",
            minWidth: 16,
            width: 90,
            textAlign: "right",
            format: "N",
          },
          {
            headerText: "매출금액",
            columns: [
              {
                field: "totAmt",
                headerText: "총매출",
                type: "number",
                minWidth: 16,
                width: 90,
                textAlign: "right",
                format: "N",
              },
              {
                field: "cleanAmtByNotVat",
                headerText: "순매출",
                type: "number",
                minWidth: 16,
                width: 90,
                textAlign: "right",
                format: "N",
              },
            ]
          },
          {
            field: "discountsLength",
            headerText: "할인건수",
            type: "number",
            minWidth: 16,
            width: 90,
            textAlign: "right",
            format: "N",
          },
          {
            field: "totDcAmt",
            headerText: "할인금액",
            type: "number",
            minWidth: 16,
            width: 90,
            textAlign: "right",
            format: "N",
          },
          {
            field: "paymentsLength",
            headerText: "결제건수",
            type: "number",
            minWidth: 16,
            width: 90,
            textAlign: "right",
            format: "N",
          },
          {
            headerText: "결제금액",
            columns: [
              {
                field: "totCardPayAmt",
                headerText: "카드",
                type: "number",
                minWidth: 16,
                width: 90,
                textAlign: "right",
                format: "N",
              },
              {
                field: "totCashPayAmt",
                headerText: "현금",
                type: "number",
                minWidth: 16,
                width: 90,
                textAlign: "right",
                format: "N",
              },
              {
                field: "totEtcPayAmt",
                headerText: "기타",
                type: "number",
                minWidth: 16,
                width: 90,
                textAlign: "right",
                format: "N",
              },
            ]
          },
          {
            field: "sexCode",
            headerText: "성별",
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
            groupCode: "SEX_CODE",
            isCommonCodeField: true,
          },
          {
            field: "nation",
            headerText: "국적",
            type: "string",
            minWidth: 16,
            width: 70,
            groupCode: "NATION_CODE",
            isCommonCodeField: true,
          },
          {
            field: "age",
            headerText: "연령",
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
          },
        ]
      };
    }
  },
  methods: {
    getDayOfWeekCaption,
    getDayOfWeekCaptionColor,
    getWeekdayWeekendCaption,
    getWeekdayWeekendCaptionColor,
    onYearMonthChange() {},
    async onSearchButtonClick() {
      const bsnDate = {
        from: moment(getFormattedDate(this.searchOptions.yearMonth.concat("-01"))).format(DATE_FORMAT_YYYY_MM_DD),
        to: moment(getFormattedDate(this.searchOptions.yearMonth.concat("-01"))).endOf('month').format(DATE_FORMAT_YYYY_MM_DD),
      };

      this.incheonAirportMonthlyClosingList = [];
      this.incheonAirportMonthlyClosingListCount = 0;
      this.incheonAirportDataInterfaceList = [];

      this.incheonAirportMonthlyClosingList = (await GolfErpAPI.fetchIncheonAirportInterfaceDataMonthCloseList({
        bsnDateFrom: bsnDate.from,
        bsnDateTo: bsnDate.to,
      })).map(data => ({
        ...data,
        bsnDate: getFormattedDate(data.bsnDate),
      }));

      this.incheonAirportMonthlyClosingListCount = this.incheonAirportMonthlyClosingList.length;
    },
    async onIncheonAirportMonthlyClosingGridRecordClick(args) {
      const {
        rowData
      } = args;

      await this.fetchAirPortIncheonInterfaceData(rowData.bsnDate);
    },
    async fetchAirPortIncheonInterfaceData(bsnDate) {
      // 일마감 여부 확인.
      const {
        data: closeDiv
      } = await GolfErpAPI.getCloseDiv({
        bsnDate: bsnDate,
        storeCode: "FRONT",
      });

      if (closeDiv !== "Y") {
        this.infoToast("일마감 후 데이터 연계를 하실 수 있습니다");
        return;
      }

      const interfaceData = await GolfErpAPI.fetchAirPortIncheonInterfaceData({
        bsnDate: bsnDate
      });

      this.settlement = interfaceData.settlement;

      console.log('0.===>', interfaceData);

      const machiningInterfaceData =
        interfaceData.teams.map(team => ({
          ...team,
          groupSalesDate: team.visitDate,
          salesDate: team.visitDate,
          salesTime: team.id === 0 ? getFormattedDate(team.visitors[0]?.sales[0]?.insertDateTime, "HH:mm:ss") : getFormattedDate(team.insertDateTime, "HH:mm:ss"),
          payments: team.visitors.flatMap(visitor => {
            let arr = [];
            visitor.payments.forEach(payment => {
              arr.push(payment);
            });

            return arr;
          }),
          returnSales: team.visitors.flatMap(visitor => {
            let arr = [];
            visitor.returnSales.forEach(sale => {
              arr.push(sale);
            });

            return arr;
          }),
          sales: team.visitors.flatMap(visitor => {
            let arr = [];
            visitor.sales.forEach(sale => {
              arr.push(sale);
            });

            return arr;
          }),
          discounts: team.visitors.flatMap(visitor => {
            let arr = [];
            visitor.sales.forEach(sale => {
              sale.discounts.forEach(discount => {
                arr.push(discount);
              });
            });

            return arr;
          })
        }));

      machiningInterfaceData.forEach(data => {
        if (data.returnSales.length > 0) {
          machiningInterfaceData.push({
            ...data,
            returnSales: [],
            discounts: [],
            sales: data.returnSales,
            payments: JSON.parse(JSON.stringify(data.payments.filter(payment => payment.amount < 0))),
          });
          data.payments = JSON.parse(JSON.stringify(data.payments.filter(payment => payment.amount > 0)));
        }
      });

      this.incheonAirportDataInterfaceList =
        JSON.parse(JSON.stringify(machiningInterfaceData)).filter(data => data.sales.length > 0).map((data,idx) => ({
          ...data,
          salesLength: data.sales.length > 0 ? data.sales.map(item => item.quantity).reduce((acc, cur) => acc + cur) : 0,
          paymentsLength: data.payments.length,
          discountsLength: data.discounts.length,
          slipNo: (idx + 1),
          totAmt: data.sales.length > 0 ? data.sales.map(item => item.amount + (item.discountAmount < 0 ? Math.abs(item.discountAmount) : 0)).reduce((acc, cur) => acc + cur) : 0,
          cleanAmt: data.sales.length > 0 ? data.sales.map(item => item.amount + (item.discountAmount < 0 ? Math.abs(item.discountAmount) : 0)).reduce((acc, cur) => acc + cur) : 0,
          totAmtByNotVat: data.sales.length > 0 ? data.sales.map(item => (item.netAmount + item.specialTax + item.educationTax + item.villagesSpecialTax + item.sportsFund) + Math.abs(item.discountAmount)).reduce((acc, cur) => acc + cur) : 0,
          cleanAmtByNotVat: data.sales.length > 0 ? data.sales.map(item => (item.netAmount + item.specialTax + item.educationTax + item.villagesSpecialTax + item.sportsFund) + Math.abs(item.discountAmount)).reduce((acc, cur) => acc + cur) : 0,
          totDcAmt: data.sales.length > 0 ? data.sales.map(item => item.discountAmount > 0 ? item.discountAmount : 0).reduce((acc, cur) => acc + cur) : 0,
          totCardPayAmt:
            (data.payments.length > 0 ?
              data.payments.map(item =>
                item.method === "CARD" ? item.amount : 0
              ).reduce((acc, cur) => acc + cur) : 0),
          totCashPayAmt:
            (data.payments.length > 0 ?
              data.payments.map(item =>
                item.method === "CASH" ? item.amount : 0
              ).reduce((acc, cur) => acc + cur) : 0),
          totEtcPayAmt:
            (data.payments.length > 0 ?
              data.payments.map(item =>
                item.method !== "CARD" && item.method !== "CASH" ? item.amount : 0
              ).reduce((acc, cur) => acc + cur) : 0),
          sexCode: data?.visitors[0]?.gender ? data?.visitors[0]?.gender : "",
          nation:
            data?.visitors[0]?.membership?.member?.details?.nationCode ?
              data?.visitors[0]?.membership?.member?.details?.nationCode : "KR",
          ageCode: this.getAgeCode(getAge(data?.visitors[0]?.membership?.member?.birthday)),
          age: this.getAgeName(getAge(data?.visitors[0]?.membership?.member?.birthday)),
        }));

      console.log('1.===>', this.incheonAirportDataInterfaceList);

      this.settlement.sumSaleAmt =
        this.incheonAirportDataInterfaceList
          .map(data => Math.abs(data.totAmt)).reduce((acc,cur) => acc + cur);
      this.settlement.sumCleanAmt =
        this.incheonAirportDataInterfaceList
          .map(data => Math.abs(data.cleanAmt)).reduce((acc,cur) => acc + cur);
      this.settlement.sumCleanAmtByNotVat =
        this.incheonAirportDataInterfaceList
          .map(data => Math.abs(data.cleanAmtByNotVat)).reduce((acc,cur) => acc + cur);

      this.settlement.sumNorCount =
        this.incheonAirportDataInterfaceList
          .map(data => data.totAmt > 0 ? 1 : 0).reduce((acc,cur) => acc + cur);
      this.settlement.sumNorAmount =
        this.incheonAirportDataInterfaceList
          .map(data => data.totAmt > 0 ? data.totAmt : 0).reduce((acc,cur) => acc + cur);
      this.settlement.sumNorCleanAmt =
        this.incheonAirportDataInterfaceList
          .map(data => data.totAmt > 0 ? data.cleanAmt : 0).reduce((acc,cur) => acc + cur);
      this.settlement.sumNorCleanAmtByNotVat =
        this.incheonAirportDataInterfaceList
          .map(data => data.totAmt > 0 ? data.cleanAmtByNotVat : 0).reduce((acc,cur) => acc + cur);

      this.settlement.sumTodayCount =
        this.incheonAirportDataInterfaceList
          .map(data => data.totAmt > 0 ? 1 : (data.totAmt < 0 ? -1 : 0)).reduce((acc,cur) => acc + cur);
      this.settlement.sumTodayAmount =
        this.incheonAirportDataInterfaceList
          .map(data => data.totAmt).reduce((acc,cur) => acc + cur);
      this.settlement.sumTodayCleanAmt =
        this.incheonAirportDataInterfaceList
          .map(data => data.cleanAmt).reduce((acc,cur) => acc + cur);
      this.settlement.sumTodayCleanAmtByNotVat =
        this.incheonAirportDataInterfaceList
          .map(data => data.cleanAmtByNotVat).reduce((acc,cur) => acc + cur);

      this.settlement.sumReturnCount =
        this.incheonAirportDataInterfaceList
          .map(data => data.totAmt < 0 ? 1 : 0).reduce((acc,cur) => acc + cur);
      this.settlement.sumReturnAmount =
        this.incheonAirportDataInterfaceList
          .map(data => data.totAmt < 0 ? Math.abs(data.totAmt) : 0).reduce((acc,cur) => acc + cur);
      this.settlement.sumReturnCleanAmt =
        this.incheonAirportDataInterfaceList
          .map(data => data.totAmt < 0 ? Math.abs(data.cleanAmt) : 0).reduce((acc,cur) => acc + cur);
      this.settlement.sumReturnCleanAmtByNotVat =
        this.incheonAirportDataInterfaceList
          .map(data => data.totAmt < 0 ? Math.abs(data.cleanAmtByNotVat) : 0).reduce((acc,cur) => acc + cur);

      this.incheonAirportDataInterfaceListCount = this.incheonAirportDataInterfaceList.length;

      console.log('2.===>', this.settlement);
    },
    getAgeName(age) {
      if (!age) {
        return "";
      }

      if (age < 20) {
        return "20 대 미만";
      } else if (age < 30) {
        return "20 대";
      } else if (age < 40) {
        return "30 대";
      } else if (age < 50) {
        return "40 대";
      } else if (age < 60) {
        return "50 대";
      } else if (age < 70) {
        return "60 대";
      } else if (age >= 70) {
        return "60 대 이상";
      }
    },
    getAgeCode(age) {
      if (!age) {
        return "";
      }

      if (age < 20) {
        return "10";
      } else if (age < 30) {
        return "20";
      } else if (age < 40) {
        return "30";
      } else if (age < 50) {
        return "40";
      } else if (age < 60) {
        return "50";
      } else if (age < 70) {
        return "60";
      } else if (age >= 70) {
        return "70";
      }
    },
    onIncheonAirportMonthlyClosingGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {field},
        },
        node,
      } = args;
      if (field === 'cardAmt') {
        node.classList.add(this.$t('className.grid.devVerticalLine'));
      }
    },
    onIncheonAirportMonthlyClosingGridQueryCellInfo(args) {
      const {
        cell,
        column: {field},
        type,
      } = args;
      if (field === 'cardAmt') {
        cell.classList.add(this.$t('className.grid.devVerticalLine'));
      }
      if (type === 'boolean') {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    onIncheonAirportMonthlyClosingGridActionComplete(args) {
      const {
        requestType,
        action,
      } = args;

      if (requestType === "filtering" && action === "clearFilter") {
        this.incheonAirportMonthlyClosingListCount = this.incheonAirportMonthlyClosingList.length;
      } else if (requestType === "filtering") {
        this.incheonAirportMonthlyClosingListCount = this.$refs.incheonAirportMonthlyClosingGrid.getFilteredRecords().length;
      }
    },
    onIncheonAirportDataInterfaceGridHeaderCellInfo() {},
    onIncheonAirportDataInterfaceGridQueryCellInfo() {},
    onIncheonAirportDataInterfaceGridActionComplete(args) {
      const {
        requestType,
        action,
      } = args;

      if (requestType === "filtering" && action === "clearFilter") {
        this.incheonAirportDataInterfaceListCount = this.incheonAirportDataInterfaceList.length;
      } else if (requestType === "filtering") {
        this.incheonAirportDataInterfaceListCount = this.$refs.incheonAirportDataInterfaceGrid.getFilteredRecords().length;
      }
    },
    async dataInterface(data, div) {
      const arrayData = data.split(";");

      console.log('arrayData.===>', arrayData);

      // 통합으로 데이터 연계하기 때문에 SA02만 체크. 이전 매출/정산 따로 데이터 연계시 각각 체크했었음.
      if (
        arrayData[0] === "SA02"
        // arrayData[0] === "ST02"
      ) {
        if (!(await this.confirm("이미 전송된 내역이 있습니다.<br>재전송하시겠습니까?"))) {
          throw false;
        }
      }

      const url = "https://csmsapi.airport.kr:8443/webapi/" + arrayData[0] + "/V100";

      const requestData = {
        URL: url,
        DATA: data,
      };

      const response = await GolfErpAPI.postAirPortIncheonInterfaceData(requestData);

      const arrResponse = (response.replaceAll("\n", "")).split(";");

      console.log('arrResponse.===>', arrResponse);

      if (arrResponse[11] !== "0000") {
        this.errorToast("데이터 연계시 오류가 발생하였습니다.<br>응답코드 : " + arrResponse[11]);
        throw false;
      }

      const request = {
        bsnDate: this.incheonAirportDataInterfaceList[0].salesDate.replaceAll("-", ""),
        linkDiv: div,
        manageNo: arrayData[5],
        requstContents: data,
        responseContents: null,
      };

      console.log('request.===>', request);

      await GolfErpAPI.airportInterfaceLogSave(request);

      // this.infoToast((div === "SALES" ? "매출 " : "정산 ") + "데이터 연계가 완료되었습니다.");
    },
    async onDataInterfaceButtonClicked() {
      if (!(this.incheonAirportDataInterfaceList.length > 0)) {
        this.infoToast("조회된 내역이 없습니다");
        return;
      }

      if (!(await this.confirm(this.incheonAirportDataInterfaceList[0].salesDate + "일자 데이터 연계를 하시겠습니까?"))) {
        return;
      }

      const salesInterfaceData = await this.setSalesInterfaceData();

      await this.dataInterface(salesInterfaceData, "SALES");

      const paymentInterfaceData = await this.setPaymentInterfaceData();

      await this.dataInterface(paymentInterfaceData, "PAYMENT");

      this.infoToast("데이터 연계가 완료되었습니다.");
    },
    async onSalesDataInterfaceButtonClicked() {
      if (!(this.incheonAirportDataInterfaceList.length > 0)) {
        this.infoToast("조회된 내역이 없습니다");
        return;
      }

      const interfaceData = await this.setSalesInterfaceData();

      this.dataInterface(interfaceData, "SALES");
    },
    async onPaymentDataInterfaceButtonClicked() {
      if (!(this.incheonAirportDataInterfaceList.length > 0)) {
        this.infoToast("조회된 내역이 없습니다");
        return;
      }

      const interfaceData = await this.setPaymentInterfaceData();

      this.dataInterface(interfaceData, "PAYMENT");
    },
    async setPaymentInterfaceData() {
      let data = {};

      const dateFromServer = await getDateFromServer();

      const logCount = await GolfErpAPI.fetchLogCountByBsnDateAndLinkDiv({
        bsnDate: this.incheonAirportDataInterfaceList[0].salesDate,
        linkDiv: "PAYMENT"
      });

      // Message Header
      data.header = [];

      data.header.push(logCount > 0 ? "ST02" : "ST01"); // 1. 전문ID. 정산전송: 'ST01', 정산재처리전송: 'ST02'.
      data.header.push("V100"); // 2. 전문버전. 'V100' 고정.
      data.header.push("HTTPS"); // 3. 전송방식.
      data.header.push(getFormattedDate(dateFromServer,"YYYYMMDD")); // 4. 전문요청일자(YYYYMMDD)
      data.header.push(getFormattedDate(dateFromServer,"HHmmss")); // 5. 전문요청시간(HHmmss)
      data.header.push("PAY".concat(getFormattedDate(dateFromServer,"YYYYMMDDHHmmss"))); // 6. 추적번호(사업자 전문요청 관리번호)
      data.header.push("2020YG11"); // 7. 공항공사 업체코드
      data.header.push("YG0001"); // 8. 공항공사 매장코드
      data.header.push(getFormattedDate(this.incheonAirportDataInterfaceList[0].salesDate, "YYYYMMDD")); // 9. 매장 영업일자(YYYYMMDD)
      data.header.push(this.settlement.posNo ? this.settlement.posNo.substr(0, 8) : "OFFICE"); // 10. POS번호
      data.header.push("1"); // 11. 전문 BODY에 포함된 총 데이터 건수

      // Message Body
      data.body = {
        settlementHeader: [],
        settlementGoods: [],
        settlementPayDiscount: [],
      };

      // SETTLEMENT_HEADER
      data.body.settlementHeader.push("2020YG11"); // 1. 업체코드
      data.body.settlementHeader.push("YG0001"); // 2. 매장코드
      data.body.settlementHeader.push(this.settlement.posNo ? this.settlement.posNo.substr(0, 8) : "OFFICE"); // 3. POS 번호
      data.body.settlementHeader.push(getFormattedDate(this.settlement.salesDate, "YYYYMMDD")); // 4. 매출일자(YYYYMMDD)
      data.body.settlementHeader.push(this.incheonAirportDataInterfaceList.length.toString()); // 5. 총거래합계건수
      data.body.settlementHeader.push(this.settlement.sumSaleAmt.toString()); // 6. 총거래합계금액
      data.body.settlementHeader.push(this.incheonAirportDataInterfaceList.filter(data => data.discountsLength > 0).length.toString()); // 7. 총거래합계할인건수
      data.body.settlementHeader.push(this.settlement.sumDcAmt.toString()); // 8. 총거래합계 할인금액(할인유형1 + 할인유형2)
      data.body.settlementHeader.push(this.settlement.sumDcAmt.toString()); // 9. 총거래합계 할인금액(순매출포함)-할인유형1
      data.body.settlementHeader.push("0"); // 10. 총거래합계 할인금액(순매출제외)-할인유형2
      data.body.settlementHeader.push(this.settlement.sumCleanAmt.toString()); // 11. 총거래합계 순매출금액(부가세포함)
      data.body.settlementHeader.push(this.settlement.sumCleanAmtByNotVat.toString()); // 12. 총거래합계 순매출금액(부가세제외)
      data.body.settlementHeader.push(this.settlement.sumNorCount.toString()); // 13. 당일정상매출 합계 건수
      data.body.settlementHeader.push(this.settlement.sumNorAmount.toString()); // 14. 당일정상매출 합계 금액
      data.body.settlementHeader.push(this.incheonAirportDataInterfaceList.filter(data => data.discountsLength > 0).length.toString()); // 15. 당일정상매출 합계 할인건수
      data.body.settlementHeader.push(this.settlement.sumDcAmt.toString()); // 16. 당일정상매출 합계 할인금액(할인유형1 + 할인유형2)
      data.body.settlementHeader.push(this.settlement.sumDcAmt.toString()); // 17. 당일정상매출 합계 할인금액(순매출포함)-할인유형1
      data.body.settlementHeader.push("0"); // 18. 당일정상매출 합계 할인금액(순매출제외)-할인유형2
      data.body.settlementHeader.push(this.settlement.sumNorCleanAmt.toString()); // 19. 당일정상매출 합계 순매출금액(부가세포함)
      data.body.settlementHeader.push(this.settlement.sumNorCleanAmtByNotVat.toString()); // 20. 당일정상매출 합계 순매출금액(부가세제외)
      data.body.settlementHeader.push(this.settlement.sumReturnCount.toString()); // 21. 당일반품매출 합계 건수
      data.body.settlementHeader.push(this.settlement.sumReturnAmount.toString()); // 22. 당일반품매출 합계 금액
      data.body.settlementHeader.push("0"); // 23. 당일반품매출 합계 할인건수
      data.body.settlementHeader.push("0"); // 24. 당일반품매출 합계 할인금액(할인유형1 + 할인유형2)
      data.body.settlementHeader.push("0"); // 25. 당일반품매출 합계 할인금액(순매출포함)-할인유형1
      data.body.settlementHeader.push("0"); // 26. 당일반품매출 합계 할인금액(순매출제외)-할인유형2
      data.body.settlementHeader.push(this.settlement.sumReturnCleanAmt.toString()); // 27. 당일반품매출 합계 순매출금액(부가세포함)
      data.body.settlementHeader.push(this.settlement.sumReturnCleanAmtByNotVat.toString()); // 28. 당일반품매출 합계 순매출금액(부가세제외)
      data.body.settlementHeader.push("0"); // 29. 과거반품매출 합계 건수
      data.body.settlementHeader.push("0"); // 30. 과거반품매출 합계 금액
      data.body.settlementHeader.push("0"); // 31. 과거반품매출 합계 할인건수
      data.body.settlementHeader.push("0"); // 32. 과거반품매출 합계 할인금액(할인유형1 + 할인유형2)
      data.body.settlementHeader.push("0"); // 33. 과거반품매출 합계 할인금액(순매출포함)-할인유형1
      data.body.settlementHeader.push("0"); // 34. 과거반품매출 합계 할인금액(순매출제외)-할인유형2
      data.body.settlementHeader.push("0"); // 35. 과거반품매출 합계 순매출금액(부가세포함)
      data.body.settlementHeader.push("0"); // 36. 과거반품매출 합계 순매출금액(부가세제외)
      data.body.settlementHeader.push(this.settlement.sumTodayCount.toString()); // 37. 당일 실매출 합계 건수
      data.body.settlementHeader.push(this.settlement.sumTodayAmount.toString()); // 38. 당일 실매출 합계 금액
      data.body.settlementHeader.push(this.incheonAirportDataInterfaceList.filter(data => data.discountsLength > 0).length.toString()); // 39. 당일 실매출 합계 할인건수
      data.body.settlementHeader.push(this.settlement.sumDcAmt.toString()); // 40. 당일 실매출 합계 할인금액(할인유형1 + 할인유형2)
      data.body.settlementHeader.push(this.settlement.sumDcAmt.toString()); // 41. 당일 실매출 합계 할인금액(순매출포함)-할인유형1
      data.body.settlementHeader.push("0"); // 42. 당일 실매출 합계 할인금액(순매출제외)-할인유형2
      data.body.settlementHeader.push(this.settlement.sumTodayCleanAmt.toString()); // 43. 당일 실매출 합계 순매출금액(부가세포함)
      data.body.settlementHeader.push(this.settlement.sumTodayCleanAmtByNotVat.toString()); // 44. 당일 실매출 합계 순매출금액(부가세제외)

      if (this.settlement?.settlementGoods && this.settlement?.settlementGoods?.length > 0) {
        this.settlement.settlementGoods.forEach(goods => {
          let products = [];

          products.push(goods.unityProductCode); // 47. CSMS 통합상품코드
          products.push(goods.sumSaleQty.toString()); // 48. 총거래합계건수
          products.push(goods.sumSaleAmt.toString()); // 49. 총거래합계금액
          products.push(goods.sumDcCount.toString()); // 50. 총거래합계할인건수
          products.push(goods.sumDcAmt.toString()); // 51. 총거래합계 할인금액(할인유형1 + 할인유형2)
          products.push(goods.sumDcAmt.toString()); // 52. 총거래합계 할인금액(순매출포함)-할인유형1
          products.push("0"); // 53. 총거래합계 할인금액(순매출제외)-할인유형2
          products.push(goods.sumSaleAmt.toString()); // 54. 총거래합계 순매출금액(부가세포함)
          products.push((goods.sumNetAmt + goods.sumDcAmt).toString()); // 55. 총거래합계 순매출금액(부가세제외)
          products.push(goods.sumSaleQtyBySale.toString()); // 56. 당일정상매출 합계 건수
          products.push(goods.sumSaleAmtBySale.toString()); // 57. 당일정상매출 합계 금액
          products.push(goods.sumDcCountBySale.toString()); // 58. 당일정상매출 합계 할인건수
          products.push(goods.sumDcAmtBySale.toString()); // 59. 당일정상매출 합계 할인금액(할인유형1 + 할인유형2)
          products.push(goods.sumDcAmtBySale.toString()); // 60. 당일정상매출 합계 할인금액(순매출포함)-할인유형1
          products.push("0"); // 61. 당일정상매출 합계 할인금액(순매출제외)-할인유형2
          products.push(goods.sumSaleAmtBySale.toString()); // 62. 당일정상매출 합계 순매출금액(부가세포함)
          products.push((goods.sumNetAmtBySale + goods.sumDcAmtBySale).toString()); // 63. 당일정상매출 합계 순매출금액(부가세제외)
          products.push(goods.sumSaleQtyByReturn.toString()); // 64. 당일반품매출 합계 건수
          products.push(goods.sumSaleAmtByReturn.toString()); // 65. 당일반품매출 합계 금액
          products.push(goods.sumDcCountByReturn.toString()); // 66. 당일반품매출 합계 할인건수
          products.push(goods.sumDcAmtByReturn.toString()); // 67. 당일반품매출 합계 할인금액(할인유형1 + 할인유형2)
          products.push(goods.sumDcAmtByReturn.toString()); // 68. 당일반품매출 합계 할인금액(순매출포함)-할인유형1
          products.push("0"); // 69. 당일반품매출 합계 할인금액(순매출제외)-할인유형2
          products.push(goods.sumSaleAmtByReturn.toString()); // 70. 당일반품매출 합계 순매출금액(부가세포함)
          products.push((goods.sumNetAmtByReturn + goods.sumDcAmtByReturn).toString()); // 71. 당일반품매출 합계 순매출금액(부가세제외)
          products.push("0"); // 72. 과거반품매출 합계 건수
          products.push("0"); // 73. 과거반품매출 합계 금액
          products.push("0"); // 74. 과거반품매출 합계 할인건수
          products.push("0"); // 75. 과거반품매출 합계 할인금액(할인유형1 + 할인유형2)
          products.push("0"); // 76. 과거반품매출 합계 할인금액(순매출포함)-할인유형1
          products.push("0"); // 77. 과거반품매출 합계 할인금액(순매출제외)-할인유형2
          products.push("0"); // 78. 과거반품매출 합계 순매출금액(부가세포함)
          products.push("0"); // 79. 과거반품매출 합계 순매출금액(부가세제외)
          products.push((goods.sumSaleQtyBySale - goods.sumSaleQtyByReturn).toString()); // 80. 당일 실매출 합계 건수
          products.push((goods.sumSaleAmtBySale - goods.sumSaleAmtByReturn).toString()); // 81. 당일 실매출 합계 금액
          products.push((goods.sumDcCountBySale - goods.sumDcCountByReturn).toString()); // 82. 당일 실매출 합계 할인건수
          products.push((goods.sumDcAmtBySale - goods.sumDcAmtByReturn).toString()); // 83. 당일 실매출 합계 할인금액(할인유형1 + 할인유형2)
          products.push((goods.sumDcAmtBySale - goods.sumDcAmtByReturn).toString()); // 84. 당일 실매출 합계 할인금액(순매출포함)-할인유형1
          products.push("0"); // 85. 당일 실매출 합계 할인금액(순매출제외)-할인유형2
          products.push((goods.sumSaleAmtBySale - goods.sumSaleAmtByReturn).toString()); // 86. 당일 실매출 합계 순매출금액(부가세포함)
          products.push(((goods.sumNetAmtBySale - goods.sumNetAmtByReturn) + goods.sumDcAmtBySale).toString()); // 87. 당일 실매출 합계 순매출금액(부가세제외)

          data.body.settlementGoods.push(products);
        });
      }

      if (this.settlement?.settlementPays && this.settlement?.settlementPays?.length > 0) {
        this.settlement.settlementPays.forEach(pay => {
          let pays = [];

          pays.push("P"); // 88. 결제/할인구분(P:결제,D:할인)
          pays.push(pay.div === "CARD" ? "P02" : (pay.div === "CASH" ? "P01" : "P10")); // 89. 결제/할인 수단분류코드
          pays.push(pay.div === "CARD" ? "P0201" : (pay.div === "CASH" ? "P0101" : "P1001")); // 90. 결제/할인 수단코드
          pays.push(pay.count); // 91. 결제 또는 할인 합계 건수
          pays.push(pay.amt); // 92. 결제 또는 할인 합계 금액
          pays.push(pay.countByPay); // 93. 당일정상매출 합계 건수
          pays.push(pay.amtByPay); // 94. 당일정상매출 합계 금액
          pays.push(pay.countByReturn); // 95. 당일반품매출 합계 건수
          pays.push(pay.amtByReturn); // 96. 당일반품매출 합계 금액
          pays.push("0"); // 97. 과거반품매출 합계 건수
          pays.push("0"); // 98. 과거반품매출 합계 금액
          pays.push(pay.count); // 99. 당일 실매출 합계 건수
          pays.push(pay.amt); // 100. 당일 실매출 합계 금액
          pays.push("N"); // 101. 할인금액 순매출 포함여부 Y/N *할인수단인 경우에만 설정함. *기본값 'N'

          data.body.settlementPayDiscount.push(pays);
        });
      }

      if (this.settlement?.settlementDiscounts && this.settlement?.settlementDiscounts?.length > 0) {
        this.settlement.settlementDiscounts.forEach(discount => {
          let discounts = [];

          discounts.push("D"); // 88. 결제/할인구분(P:결제,D:할인)
          discounts.push("D01"); // 89. 결제/할인 수단분류코드
          discounts.push( // 90. 결제/할인 수단코드
            discount.div === "NOR" ?
              "D0101" : // 임의할인 정액
              (discount.div === "COUPON" ?
                  "D0107" : // 자체쿠폰할인
                  (discount.div === "CMPN" ?
                      "D0105" : // 멤버십할인(사업자 자체 멤버십에 의한 할인)
                      (discount.div === "PROMTN" ?
                          "D0111" : // 자체프로모션할인
                          "D0101"
                      )
                  )
              )
          );
          discounts.push(discount.count); // 91. 결제 또는 할인 합계 건수
          discounts.push(discount.amt); // 92. 결제 또는 할인 합계 금액
          discounts.push(discount.count); // 93. 당일정상매출 합계 건수
          discounts.push(discount.amt); // 94. 당일정상매출 합계 금액
          discounts.push("0"); // 95. 당일반품매출 합계 건수
          discounts.push("0"); // 96. 당일반품매출 합계 금액
          discounts.push("0"); // 97. 과거반품매출 합계 건수
          discounts.push("0"); // 98. 과거반품매출 합계 금액
          discounts.push(discount.count); // 99. 당일 실매출 합계 건수
          discounts.push(discount.amt); // 100. 당일 실매출 합계 금액
          discounts.push("Y"); // 101. 할인금액 순매출 포함여부 Y/N *할인수단인 경우에만 설정함. *기본값 'N'

          data.body.settlementPayDiscount.push(discounts);
        });
      }

      data.body.settlementHeader.push(data.body.settlementGoods.length.toString()); // 45. 상품집계내역 총 건수
      data.body.settlementHeader.push(data.body.settlementPayDiscount.length.toString()); // 46. 결제할인집계내역 총 건수

      console.log('paymentInterfaceData.===>', data);

      let returnData = "";

      returnData += data.header.join(";");
      returnData += "\n";

      returnData += data.body.settlementHeader.join(";");
      returnData += "#;";

      data.body.settlementGoods.forEach((goods, idx) => {
        returnData += goods.join(";");
        if ((idx + 1) < data.body.settlementGoods.length) {
          returnData += "$;";
        }
      });
      returnData += "#;";

      data.body.settlementPayDiscount.forEach((item, idx) => {
        returnData += item.join(";");
        if ((idx + 1) < data.body.settlementPayDiscount.length) {
          returnData += "$;";
        }
      });
      returnData += "\n";

      return returnData;
    },
    async setSalesInterfaceData() {
      const { value: bsnInfo } = await getStoreBsnMstInfo("FRONT");

      const {
        vanKind,
        termId
      } = bsnInfo;

      let data = {};

      const dateFromServer = await getDateFromServer();

      const logCount = await GolfErpAPI.fetchLogCountByBsnDateAndLinkDiv({
        bsnDate: this.incheonAirportDataInterfaceList[0].salesDate,
        linkDiv: "SALES"
      });

      // Message Header
      data.header = [];

      data.header.push(logCount > 0 ? "SA02" : "SA01"); // 1. 전문ID. 기본매출전송: 'SA01', 매출재처리전송: 'SA02'.
      data.header.push("V100"); // 2. 전문버전. 'V100' 고정.
      data.header.push("HTTPS"); // 3. 전송방식.
      data.header.push(getFormattedDate(dateFromServer,"YYYYMMDD")); // 4. 전문요청일자(YYYYMMDD)
      data.header.push(getFormattedDate(dateFromServer,"HHmmss")); // 5. 전문요청시간(HHmmss)
      data.header.push("SALES".concat(getFormattedDate(dateFromServer,"YYYYMMDDHHmmss"))); // 6. 추적번호(사업자 전문요청 관리번호)
      data.header.push("2020YG11"); // 7. 공항공사 업체코드
      data.header.push("YG0001"); // 8. 공항공사 매장코드
      data.header.push(getFormattedDate(this.incheonAirportDataInterfaceList[0].salesDate, "YYYYMMDD")); // 9. 매장 영업일자(YYYYMMDD)
      data.header.push(this.incheonAirportDataInterfaceList[0].posNo ? this.incheonAirportDataInterfaceList[0].posNo.substr(0, 8) : "OFFICE"); // 10. POS번호

      // Message Body
      data.body = [];

      this.incheonAirportDataInterfaceList.forEach(rowData => {
        const rowObject = {
          trHeader: [],
          trDetail: [],
          trPayment: [],
          trDiscount: [],
        };

        let nation = "";

        switch (rowData.nation) {
          case "KR": // 한국
            nation = "KOR";
            break;
          case "JP": // 일본
            nation = "JPN";
            break;
          case "CH": // 중국
            nation = "CHN";
            break;
          case "EA": // 동남아
            nation = "SEA";
            break;
          case "EU","BR","FI","GM": // 유럽,영국,핀란드,독일
            nation = "EUR";
            break;
          case "AU": // 오세아니아
            nation = "OCE";
            break;
          default:
            nation = "KOR";
            break;
        }

        // TR_HEADER
        rowObject.trHeader.push("2020YG11"); // 1. 업체코드
        rowObject.trHeader.push("YG0001"); // 2. 매장코드
        rowObject.trHeader.push(rowData.posNo ? rowData.posNo.substr(0, 8) : "OFFICE"); // 3. POS번호
        rowObject.trHeader.push(getFormattedDate(rowData.salesDate, "YYYYMMDD")); // 4. 매출일자(YYYYMMDD)
        rowObject.trHeader.push(rowData.salesTime.replaceAll(":","")); // 5. 매출시간
        rowObject.trHeader.push(rowData.slipNo.toString()); // 6. 매출번호(영수증번호)
        rowObject.trHeader.push(rowData.salesLength < 0 ? "05" : "01"); // 7. 매출구분(01: 정상, 05: 반품 으로 구분하여 입력)
        rowObject.trHeader.push(rowData.totDcAmt.toString()); // 8. 총할인금액(할인유형1 + 할인유형2)
        rowObject.trHeader.push(rowData.totDcAmt.toString()); // 9. 할인금액(순매출 포함) - 할인유형1
        rowObject.trHeader.push("0"); // 10. 할인금액(순매출 제외) - 할인유형2
        rowObject.trHeader.push(Math.abs(rowData.totAmt).toString()); // 11. 총매출금액(부가세 포함)
        rowObject.trHeader.push(Math.abs(rowData.cleanAmt).toString()); // 12. 순매출금액(부가세 포함)
        rowObject.trHeader.push(Math.abs(rowData.totAmtByNotVat).toString()); // 13. 총매출금액(부가세 제외)
        rowObject.trHeader.push(Math.abs(rowData.cleanAmtByNotVat).toString()); // 14. 순매출금액(부가세 제외)
        // 순매출금액은 총금액 - 할인금액.
        rowObject.trHeader.push("0"); // 15. 외화 - 총할인금액(할인유형1 + 할인유형2)
        rowObject.trHeader.push("0"); // 16. 외화 - 할인금액(순매출 포함) - 할인유형1
        rowObject.trHeader.push("0"); // 17. 외화 - 할인금액(순매출 제외) - 할인유형2
        rowObject.trHeader.push("0"); // 18. 외화 - 총매출금액(부가세 제외)
        rowObject.trHeader.push("0"); // 19. 외화 - 순매출금액(부가세 제외)
        rowObject.trHeader.push(Math.abs(rowData.totCashPayAmt).toString()); // 20. 현금결제액
        rowObject.trHeader.push(Math.abs(rowData.totCardPayAmt).toString()); // 21. 카드결제액
        rowObject.trHeader.push(Math.abs(rowData.totEtcPayAmt).toString()); // 22. 기타결제액(현금,카드를 제외한 결제액 합계)
        rowObject.trHeader.push("0"); // 23. 외화 - 현금결제액(USD)
        rowObject.trHeader.push("0"); // 24. 외화 - 카드결제액(USD)
        rowObject.trHeader.push("0"); // 25. 외화 - 기타결제액(USD)(현금,카드를 제외한 외화 결제액 합계)
        rowObject.trHeader.push("USD"); // 26. 외환코드(USD, JPY, CNY, ...)('USD' 고정)
        rowObject.trHeader.push("0"); // 27. 외환결제액 (외화 결제 합계금액)
        rowObject.trHeader.push(rowData.salesLength < 0 ? getFormattedDate(rowData.salesDate, "YYYYMMDD") : ""); // 28. 원매출일자 * 반품 매출인 경우 필수입력
        rowObject.trHeader.push(rowData.salesLength < 0 ? (rowData.posNo ? rowData.posNo.substr(0, 8) : "OFFICE") : ""); // 29. 원 POS 번호 * 반품 매출인 경우 필수입력
        rowObject.trHeader.push(rowData.salesLength < 0 ? "000000" : ""); // 30. 원매출번호 * 반품 매출인 경우 필수입력
        rowObject.trHeader.push(rowData.salesLength < 0 ? "10" : ""); // 31. 반품사유 * 반품 매출인 경우 필수입력
                                     // (01:상품불량,02:상품교환,03:구색부족,04:사이즈 착오,05:고객변심,06:계산착오,07:지불방법변경,08:이중구입,09:매출보정,10:기타사유,11:오타등록)
        rowObject.trHeader.push(rowData.sexCode === "F" ? "2" : "1"); // 32. 성별(1:남, 2:여)
        rowObject.trHeader.push(nation); // 33. 국적(참조: ISO NATIONAL CODE LIST) * 별첨 코드정의 참조
        rowObject.trHeader.push(rowData.ageCode); // 34. 연령 * 별첨 코드정의 참조
        rowObject.trHeader.push(""); // 35. 항공편명
        rowObject.trHeader.push(""); // 36. 운항일자(YYYYMMDD 형식)
        rowObject.trHeader.push(""); // 37. 출발지
        rowObject.trHeader.push(""); // 38. 도착지

        rowData.sales.forEach((sale, idx) => {
          let details = [];

          details.push((idx + 1).toString()); // 45. 매출순번
          details.push(sale.unityProductCode); // 46. CSMS 통합 상품/메뉴 코드
          details.push(sale.corpProductCode); // 47. 공항공사 품목코드
          details.push(sale.quantity < 0 ? "05" : "01"); // 48. 매출구분(01:정상,05:반품 으로 구분하여 입력)
          details.push(sale.noTaxCode === "NOTAX" ? "N" : "Y"); // 49. 과세면세 구분 (Y:과세, N:면세)
          details.push(Math.abs(sale.quantity).toString()); // 50. 판매수량
          details.push(Math.abs(sale.price).toString()); // 51. 단가
          details.push((sale.discountAmount < 0 ? 0 : sale.discountAmount).toString()); // 52. 총할인금액(할인유형1 + 할인유형2)
          details.push((sale.discountAmount < 0 ? 0 : sale.discountAmount)); // 53. 할인금액(순매출 포함) - 할인유형1
          details.push("0"); // 54. 할인금액(순매출 제외) - 할인유형2
          details.push((Math.abs(sale.amount) + (sale.discountAmount < 0 ? Math.abs(sale.discountAmount) : 0)).toString()); // 55. 총매출금액(부가세 포함)
          details.push((Math.abs(sale.amount) + (sale.discountAmount < 0 ? Math.abs(sale.discountAmount) : 0)).toString()); // 56. 순매출금액(부가세 포함)
          details.push(Math.abs((sale.netAmount + sale.specialTax + sale.educationTax + sale.villagesSpecialTax + sale.sportsFund) + Math.abs(sale.discountAmount)).toString()); // 57. 총매출금액(부가세 제외)
          details.push(Math.abs((sale.netAmount + sale.specialTax + sale.educationTax + sale.villagesSpecialTax + sale.sportsFund) + Math.abs(sale.discountAmount)).toString()); // 58. 순매출금액(부가세 제외)
          details.push("0"); // 59. 외화 - 총할인금액(할인유형1 + 할인유형2)
          details.push("0"); // 60. 외화 - 할인금액(순매출 포함) - 할인유형1
          details.push("0"); // 61. 외화 - 할인금액(순매출 제외) - 할인유형2
          details.push("0"); // 62. 외화 - 총매출금액(부가세 제외)
          details.push("0"); // 63. 외화 - 순매출금액(부가세 제외)
          details.push(""); // 64. 예약필드 1
          details.push(""); // 65. 예약필드 2
          details.push(""); // 66. 예약필드 3

          rowObject.trDetail.push(details);

          sale.discounts.forEach((discount, idx) => {
            let discounts = [];

            discounts.push((idx + 1).toString()); // 91. 할인전표순번
            discounts.push("D01"); // 92. 할인수단분류코드 * 별첨. 코드정의 참조
            discounts.push( // 93. 할인수단코드 * 별첨. 코드정의 참조
              discount.applyDivision === "NOR" ?
                (
                  discount.method === "RATE" ?
                    "D0102" : // 임의할인 정율
                    "D0101" // 임의할인 정액
                ) :
                (discount.applyDivision === "COUPON" ?
                    "D0107" : // 자체쿠폰할인
                    (discount.applyDivision === "CMPN" ?
                        "D0105" : // 멤버십할인(사업자 자체 멤버십에 의한 할인)
                        (discount.applyDivision === "PROMTN" ?
                            "D0111" : // 자체프로모션할인
                            "D0101"
                        )
                    )
                )
            );
            discounts.push("Y"); // 94. 할인금액 순매출포함 여부(Y:포함,N:제외)
            discounts.push(discount.amount.toString()); // 95. 총할인금액(할인유형1 + 할인유형2)
            discounts.push(discount.amount.toString()); // 96. 할인금액(순매출 포함)-할인유형1
            discounts.push("0"); // 97. 할인금액(순매출 제외)-할인유형2
            discounts.push("0"); // 98. 외화-총할인금액(할인유형1 + 할인유형2)
            discounts.push("0"); // 99. 외화-할인금액(순매출 포함)-할인유형1
            discounts.push("0"); // 100. 외화-할인금액(순매출 제외)-할인유형2
            discounts.push(""); // 101. 사업자 프로모션 코드. Ex) 면세사업자 프로모션 코드
            discounts.push(""); // 102. 예약필드 1
            discounts.push(""); // 103. 예약필드 2
            discounts.push(""); // 104. 예약필드 3

            rowObject.trDiscount.push(discounts);
          });
        });

        rowData.payments.forEach((payment, idx) => {
          let payments = [];

          payments.push((idx + 1).toString()); // 67. 결제순번
          payments.push(payment.method === "CARD" ? "P02" : (payment.method === "CASH" ? "P01" : "P10")); // 68. 결제수단분류코드 * 별첨 코드정의 참조
          payments.push(payment.method === "CARD" ? "P0201" : (payment.method === "CASH" ? "P0101" : "P1001")); // 69. 결제수단코드 * 별점 코드정의 참조
          payments.push("KRW"); // 70. 결제화폐구분(실 결제화폐)
          payments.push(vanKind); // 71. 승인사코드(통합VAN)
          payments.push(payment.termId ? payment.termId : termId); // 72. 승인사 가맹번호
          payments.push(payment.termId ? payment.termId : termId); // 73. 승인사 단말기번호
          payments.push(payment.method === "CARD" ? "0" : "9"); // 74. 승인식별번호 입력구분(유형)
          // (0:IC,1:MSR,2:Barcode,3:QR코드,4:OTC,5:APP결제,9:KEY-IN(수기입력)
          payments.push( // 75. 승인식별번호
            payment.method === "CARD" ?
              (payment?.payCard?.cardNo ? payment?.payCard?.cardNo.replaceAll("-","") : "") :
              (payment.method === "CASH" ? (payment?.payCash?.proofKind === "VOL" ? (payment?.payCash?.proofNo ? payment?.payCash?.proofNo : "") : "") : "")
          );
          payments.push( // 76. 승인번호
            payment.method === "CARD" ?
              (payment?.payCard?.approvalNo ? payment?.payCard?.approvalNo : (payment?.approvalNumber ? payment?.approvalNumber : "")) :
              (payment.method === "CASH" ? (payment?.payCash?.approvalNo ? payment?.payCash?.approvalNo : (payment?.approvalNumber ? payment?.approvalNumber : "")) : (payment?.approvalNumber ? payment?.approvalNumber : ""))
          );
          payments.push( // 77. 승인일자
            payment.method === "CARD" ?
              (payment?.payCard?.approvalDateTime ? getFormattedDate(payment?.payCard?.approvalDateTime, "YYYYMMDD") : getFormattedDate(payment?.insertDate, "YYYYMMDD")) :
              (payment.method === "CASH" ? (payment?.payCash?.approvalDateTime ? getFormattedDate(payment?.payCash?.approvalDateTime, "YYYYMMDD") : getFormattedDate(payment?.insertDate, "YYYYMMDD")) : getFormattedDate(payment?.insertDate, "YYYYMMDD"))
          );
          payments.push( // 78. 승인일시
            payment.method === "CARD" ?
              (payment?.payCard?.approvalDateTime ? getFormattedDate(payment?.payCard?.approvalDateTime, "HHmmss") : getFormattedDate(payment?.insertDate, "HHmmss")) :
              (payment.method === "CASH" ? (payment?.payCash?.approvalDateTime ? getFormattedDate(payment?.payCash?.approvalDateTime, "HHmmss") : getFormattedDate(payment?.insertDate, "HHmmss")) : getFormattedDate(payment?.insertDate, "HHmmss"))
          );
          payments.push(Math.abs(payment.amount).toString()); // 79. 승인요청금액
          payments.push(Math.abs(payment.amount).toString()); // 80. 실승인금액
          payments.push("0"); // 81. 결제금액_달러
          payments.push(payment.id.toString()); // 82. 승인거래추적번호(승인요청자의 요청건 고유번호)(PAY_ID 사용)
          payments.push(payment.amount < 0 ? "1" : "0"); // 83. 승인구분(승인거래유형)(0:정상,1:취소)
          payments.push(payment?.payCard?.approvalNo || payment?.payCash?.approvalNo ? "N" : "Y"); // 84. 승인오프라인여부(N:온라인승인,Y:오프라인승인(수기/임의 등록)
          payments.push(payment.amount < 0 ? "00000000" : ""); // 85. 원거래승인번호
          payments.push(payment.amount < 0 ? getFormattedDate(payment?.insertDate, "YYYYMMDD") : ""); // 86. 원거래승인일자
          payments.push(payment.amount < 0 ? getFormattedDate(payment?.insertDate, "HHmmss") : ""); // 87. 원거래승인일시
          payments.push(""); // 88. 예약필드 1
          payments.push(""); // 89. 예약필드 2
          payments.push(""); // 90. 예약필드 3

          rowObject.trPayment.push(payments);
        });

        rowObject.trHeader.push(rowObject.trDetail.length.toString()); // 39. Detail 건수
        rowObject.trHeader.push(rowObject.trPayment.length.toString()); // 40. 결제수단 총 건수
        rowObject.trHeader.push(rowObject.trDiscount.length.toString()); // 41. 할인수단 총 건수
        rowObject.trHeader.push(""); // 42. 예약필드 1
        rowObject.trHeader.push(""); // 43. 예약필드 2
        rowObject.trHeader.push(""); // 44. 예약필드 3

        data.body.push(rowObject);
      });

      data.header.push(data.body.length.toString()); // 11. 전문 BODY에 포함된 총 데이터 건수

      console.log('salesInterfaceData.===>', data);

      let returnData = "";

      returnData += data.header.join(";");
      returnData += "\n";

      data.body.forEach(body => {
        returnData += body.trHeader.join(";");
        returnData += "#;";

        body.trDetail.forEach((detail, idx) => {
          returnData += detail.join(";");
          if ((idx + 1) < body.trDetail.length) {
            returnData += "$;";
          }
        });
        returnData += "#;";

        body.trPayment.forEach((payment, idx) => {
          returnData += payment.join(";");
          if ((idx + 1) < body.trPayment.length) {
            returnData += "$;";
          }
        });
        returnData += "#;";

        body.trDiscount.forEach((discount, idx) => {
          returnData += discount.join(";");
          if ((idx + 1) < body.trDiscount.length) {
            returnData += "$;";
          }
        });
        if (body.trDiscount.length > 0) {
          returnData += "#;";
        }

        returnData += "\n";
      });

      return returnData;
    }
  }
};
</script>
